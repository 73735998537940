import {createSlice} from '@reduxjs/toolkit';

export const subgrupoSlice = createSlice({
    name: 'subgrupo',
    initialState: {
        subgrupo: [],
        subgrupoIds: []
    },
    reducers: {
        subgrupoReducer: (state, action) => {
            state.subgrupo = action.payload;
        },
        subgrupoIdsReducer: (state, action) => {
            state.subgrupoIds = action.payload;
        },
        subgrupoDashboardReducer: (state, action) => {
            state.dashboardSubgrupo = action.payload;
        },
        subgrupoDashboardIdsReducer: (state, action) => {
            state.dashboardSubgrupoIds = action.payload;
        }
    }
});

export const {
    subgrupoReducer,
    subgrupoIdsReducer,
    subgrupoDashboardReducer,
    subgrupoDashboardIdsReducer
} = subgrupoSlice.actions;
