import {createSlice} from '@reduxjs/toolkit';

export const filiaisSlice = createSlice({
    name: 'filiais',
    initialState: {
        filiais: [],
        filiaisIds: []
    },
    reducers: {
        filiaisReducer: (state, action) => {
            state.filiais = action.payload;
        },
        filiaisIdsReducer: (state, action) => {
            state.filiaisIds = action.payload;
        },
        filiaisDashboardReducer: (state, action) => {
            state.dashboardFiliais = action.payload;
        },
        filiaisDashboardIdsReducer: (state, action) => {
            state.dashboardFiliaisIds = action.payload;
        }
    }
});

export const {
    filiaisReducer,
    filiaisIdsReducer,
    filiaisDashboardReducer,
    filiaisDashboardIdsReducer
} = filiaisSlice.actions;
