import {createSlice} from '@reduxjs/toolkit';

export const vendedoresSlice = createSlice({
    name: 'vendedores',
    initialState: {
        vendedores: [],
        vendedoresIds: [],
        supervisores: [],
        supervisoresIds: [],
        dashboardSupervisores: [],
        dashboardSupervisoresIds: []
    },
    reducers: {
        vendedoresReducer: (state, action) => {
            state.vendedores = action.payload;
        },
        vendedoresIdsReducer: (state, action) => {
            state.vendedoresIds = action.payload;
        },
        vendedoresIdsDashboardReducer: (state, action) => {
            state.vendedoresDashboardIds = action.payload;
        },
        vendedoresDashboardReducer: (state, action) => {
            state.vendedoresDashboardReducer = action.payload;
        },
        supervisoresReducer: (state, action) => {
            state.supervisores = action.payload;
        },
        supervisoresIdsReducer: (state, action) => {
            state.supervisoresIds = action.payload;
        },
        supervisoresDashboardReducer: (state, action) => {
            state.dashboardSupervisores = action.payload;
        },
        supervisoresDashboardIdsReducer: (state, action) => {
            state.dashboardSupervisoresIds = action.payload;
        }
    }
});

export const {
    vendedoresDashboardReducer,
    vendedoresIdsDashboardReducer,
    vendedoresReducer,
    vendedoresIdsReducer,
    supervisoresReducer,
    supervisoresIdsReducer,
    supervisoresDashboardReducer,
    supervisoresDashboardIdsReducer
} = vendedoresSlice.actions;
