/* eslint-disable prettier/prettier */
import React, {lazy, Suspense} from 'react';
import {Switch} from 'react-router-dom';
import LoadingSIG from '../../../components/loading';
import PrivateRoute from '../../../components/router/PrivateRoute';
import { ALTERAR_SENHA_ROUTE_PATH } from '../../../constants';

export const ClienteRoute = () => {
    const Dashboard = lazy(() => import('../../../pages/dashboard/Dashboard'));
    const Perfis = lazy(() => import('../../../pages/perfis'));
    const CadastrarPerfil = lazy(() => import('../../../pages/perfis/CadastrarPerfil'));
    const Usuarios = lazy(() => import('../../../pages/usuarios'));
    const CadastrarUsuario = lazy(() => import('../../../pages/usuarios/CadastrarUsuario'));
    const Dispositivos = lazy(() => import('../../../pages/dispositivos'));
    const Chart = lazy(() => import('../../../pages/charts/index'));
    const CadastrarDispositivo = lazy(() => import('../../../pages/dispositivos/CadastrarDispositivo'));
    const HistoricoDeCompras = lazy(() => import('../../../pages/nota-fiscal/HistoricoDeCompras'));
    const NotaDetalhada = lazy(() => import('../../../pages/nota-fiscal/NotaDetalhada'));
    const Metas = lazy(() => import('../../../pages/metas'));
    const TituloFinanceiro = lazy(() => import('../../../pages/titulo-financeiro/TituloFinanceiro'));
    const Produtos = lazy(() => import('../../../pages/produtos'));
    const TabelaPreco = lazy(() => import('../../../pages/tabelas-de-preco'));
    const PrazoPagamento = lazy(() => import('../../../pages/prazo-pagamento'));
    const Clientes = lazy(() => import('../../../pages/clientes'));
    const FormaPagamento = lazy(() => import('../../../pages/forma-pagamento'));
    const SenhasGeradas = lazy(() => import('../../../pages/gerar-senha'));
    const GerarSenha = lazy(() => import('../../../pages/liberacao-senha/GerarSenha'));
    const PedidosAndamentoPage = lazy(() => import('../../../pages/pedidos/PedidosAndamentoPage'));
    const PedidosCoordenadasPage = lazy(() => import('../../../pages/pedidos/PedidosCoordenadasPage'));
    const GeoLocaliVendedoresPage = lazy(() => import('../../../pages/geolocalizacao/Vendedores'));
    const IntegracaoMagentoPage = lazy(() => import('../../../pages/integracao-magento/IntegracaoMagentoPage'));
    const TermosPedidoWeb = lazy(() => import('../../../pages/termos-pedido-web/TermosPedidoWeb'));
    const IntegracaoVendasExternasPage = lazy(() => import('../../../pages/integracao-vendas-externas/IntegracaoVendasExternasPage'));
    const SegurosPage = lazy(() => import('../../../pages/seguros/SegurosPage'));
    const SincronismoAfvPage = lazy(() => import('../../../pages/sincronismo-afv/SincronismoAfvPage'));
    const AlterarForcaDeSenha = lazy(() => import('../../../pages/configuracoes/AlterarSenha'));
    const GeoLocaliPedidosPage = lazy(() => import('../../../pages/geolocalizacao/Pedidos'));
    const FaqPage = lazy(() => import('../../../pages/faq'));
    const Atualizacoes = lazy(() => import('../../../pages/atualizacoes'));
    const MonitoramentoPage = lazy(() => import('../../../pages/visitas/MonitoramentoPage'));
    const MargemMarkupPage = lazy(() => import('../../../pages/margem-markup'));
    const AgendaPage = lazy(() => import('../../../pages/visitas/AgendaPage'));
    const IntegracaoBluesoftPage = lazy(() => import('../../../pages/integracao-bluesoft/IntegracaoBluesoftPage'));
    const IntegracaoShopifyPage = lazy(() => import('../../../pages/integracao-shopify/IntegracaoShopifyPage'));
    const Configuracoes = lazy(() => import('../../../pages/configuracoes'));
    const Campanha = lazy(() => import('../../../pages/campanha'));
    const Vendedores = lazy(() => import('../../../pages/vendedores'));

    return (
        <Suspense fallback={<LoadingSIG />}>
            <Switch>
                <PrivateRoute exact path="/relatorios" component={Dashboard} />
                <PrivateRoute exact path="/relatorios/:group/:id/:chartType" component={Chart} />
                <PrivateRoute exact path="/pedidos" component={PedidosAndamentoPage} />
                <PrivateRoute exact path="/pedidos/coordenadas" component={PedidosCoordenadasPage} />
                <PrivateRoute exact path="/vendedores/geolocalizacao" component={GeoLocaliVendedoresPage} />
                <PrivateRoute exact path="/pedidos/geolocalizacao" component={GeoLocaliPedidosPage} />
                <PrivateRoute exact path="/pedidos/geolocalizacao/:idVendedor?/:dateIniCalendar?/:dateFimCalendar?" component={GeoLocaliPedidosPage} />
                <PrivateRoute path="/visitas/agenda" component={AgendaPage} />
                <PrivateRoute path="/visitas/monitoramento" component={MonitoramentoPage} />
                <PrivateRoute exact path="/token" component={SenhasGeradas} />
                <PrivateRoute exact path="/token/gerar" component={GerarSenha} />
                <PrivateRoute exact path="/produtos" component={Produtos} />
                <PrivateRoute exact path="/clientes" component={Clientes} />
                <PrivateRoute exact path="/vendedores" component={Vendedores} />
                <PrivateRoute exact path="/prazos-pagamento" component={PrazoPagamento} />
                <PrivateRoute exact path="/tabela-preco" component={TabelaPreco} />
                <PrivateRoute exact path="/formas-pagamento" component={FormaPagamento} />
                <PrivateRoute exact path="/notafiscal" component={HistoricoDeCompras} />
                <PrivateRoute exact path="/margem/markup" component={MargemMarkupPage} />
                <PrivateRoute path="/notafiscal/:cod" component={NotaDetalhada} />
                <PrivateRoute exact path="/objetivos" component={Metas} />
                <PrivateRoute exact path="/financeiro" component={TituloFinanceiro} />
                <PrivateRoute path="/sincronismo-afv" component={SincronismoAfvPage} />
                <PrivateRoute path="/vendas-externas" component={IntegracaoVendasExternasPage}/>
                <PrivateRoute path="/integracao-magento" component={IntegracaoMagentoPage}/>
                <PrivateRoute path="/integracao-bluesoft" component={IntegracaoBluesoftPage}/>
                <PrivateRoute path="/integracao-shopify" component={IntegracaoShopifyPage}/>
                <PrivateRoute exact path="/usuarios" component={Usuarios} />
                <PrivateRoute exact path="/dispositivo" component={Dispositivos} />
                <PrivateRoute path="/dispositivo/:cod" component={CadastrarDispositivo} />
                <PrivateRoute path="/usuarios/:cod" component={CadastrarUsuario} />
                <PrivateRoute exact path="/perfis" component={Perfis} />
                <PrivateRoute path="/perfis/:cod" component={CadastrarPerfil} />
                <PrivateRoute path="/pedidoweb/termo" component={TermosPedidoWeb} />
                <PrivateRoute path="/valores-seguro" component={SegurosPage} />
                <PrivateRoute path="/faq" component={FaqPage} />
                <PrivateRoute path="/atualizacoes" component={Atualizacoes} />
                <PrivateRoute path="/configuracoes" component={Configuracoes} />
                <PrivateRoute path="/campanhas" component={Campanha} />
                <PrivateRoute path={ALTERAR_SENHA_ROUTE_PATH} component={AlterarForcaDeSenha} />
            </Switch>
        </Suspense>
    );
};
