import {createSlice} from '@reduxjs/toolkit';

export const ordenacaoDashboardSlice = createSlice({
    name: 'ordenacaoDashboard',
    initialState: {
        ordenacaoDashboard: {
            ordenacao: [],
            setOrdenacao: []
        }
    },
    reducers: {
        setOrdenacaoDashboard: (state, action) => {
            state.ordenacaoDashboard.ordenacao = action.payload;
        },
        setCheckedOrdenacaoDashboard: (state, action) => {
            state.ordenacaoDashboard.setOrdenacao = action.payload;
        }
    }
});

export const {setOrdenacaoDashboard, setCheckedOrdenacaoDashboard} =
    ordenacaoDashboardSlice.actions;
