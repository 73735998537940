import {createSlice} from '@reduxjs/toolkit';

export const pedidoSlice = createSlice({
    name: 'pedido',
    initialState: {
        status: {
            statusPedidos: [],
            checkedValuesPedidos: []
        },
        formaPgto: {
            listFormaPgto: [],
            checkedValuesFormaPgto: []
        },
        tabelaPreco: {
            listTabelaPreco: [],
            checkedValuesTabelaPreco: []
        },
        prazoPgto: {
            listPrazoPgto: [],
            checkedValuesPrazoPgto: []
        },
        tipoPedido: {
            listTipoPedido: [],
            checkedValuesTipoPedido: []
        }
    },
    reducers: {
        setPedidosAndamentoPedidos: (state, action) => {
            state.status.statusPedidos = action.payload;
        },
        setCheckedValuesAndamento: (state, action) => {
            state.status.checkedValuesPedidos = action.payload;
        },
        statusPedidoDashboardReduce: (state, action) => {
            state.status.statusPedidosDashboard = action.payload;
        },
        statusPedidoDashboardIdsReduce: (state, action) => {
            state.status.statusPedidosDashboardIds = action.payload;
        },
        setFormaPgto: (state, action) => {
            state.formaPgto.listFormaPgto = action.payload;
        },
        setCheckedValuesFormaPgto: (state, action) => {
            state.formaPgto.checkedValuesFormaPgto = action.payload;
        },
        setTabelaPreco: (state, action) => {
            state.tabelaPreco.listTabelaPreco = action.payload;
        },
        setCheckedValuesTabelaPreco: (state, action) => {
            state.tabelaPreco.checkedValuesTabelaPreco = action.payload;
        },
        setPrazoPgto: (state, action) => {
            state.prazoPgto.listPrazoPgto = action.payload;
        },
        setCheckedValuesPrazoPgto: (state, action) => {
            state.prazoPgto.checkedValuesPrazoPgto = action.payload;
        },
        setTipoPedido: (state, action) => {
            state.tipoPedido.listTipoPedido = action.payload;
        },
        setCheckedValuesTipoPedido: (state, action) => {
            state.tipoPedido.checkedValuesTipoPedido = action.payload;
        },
        tipoPedidoDashboardReduce: (state, action) => {
            state.tipoPedido.tipoDePedidoDashboard = action.payload;
        },
        tipoPedidoIdsDashboardReduce: (state, action) => {
            state.tipoPedido.tipoDePedidoIdsDashboard = action.payload;
        }
    }
});

export const {
    setPedidosAndamentoPedidos,
    setCheckedValuesAndamento,
    setFormaPgto,
    setCheckedValuesFormaPgto,
    setTabelaPreco,
    setCheckedValuesTabelaPreco,
    setPrazoPgto,
    setCheckedValuesPrazoPgto,
    setTipoPedido,
    setCheckedValuesTipoPedido,
    tipoPedidoDashboardReduce,
    tipoPedidoIdsDashboardReduce,
    statusPedidoDashboardReduce,
    statusPedidoDashboardIdsReduce
} = pedidoSlice.actions;
