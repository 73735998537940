import {createSlice} from '@reduxjs/toolkit';

export const grupoSlice = createSlice({
    name: 'grupo',
    initialState: {
        grupo: [],
        grupoIds: []
    },
    reducers: {
        grupoReducer: (state, action) => {
            state.grupo = action.payload;
        },
        grupoIdsReducer: (state, action) => {
            state.grupoIds = action.payload;
        },
        grupoDashboardReducer: (state, action) => {
            state.dashboardGrupo = action.payload;
        },
        grupoDashboardIdsReducer: (state, action) => {
            state.dashboardGrupoIds = action.payload;
        }
    }
});

export const {
    grupoReducer,
    grupoIdsReducer,
    grupoDashboardReducer,
    grupoDashboardIdsReducer
} = grupoSlice.actions;
